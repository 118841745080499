<template lang="pug">
#order
    ul.navbox-li
        li(:class="{ active: active == -1 }", @click="chooseTab(-1)") 全部订单
        li(:class="{ active: active == 0 }", @click="chooseTab(0)") 待审核
        li(:class="{ active: active == 1 }", @click="chooseTab(1)") 已审核
    .order-box(v-if="active == -1") 
        van-list.xin-box(
            v-model="loading",
            :finished="finished",
            @load="onLoad"
        )
            template(#default)
                .order1-box(v-for="item in list")
                    .order-t
                        p.num 订单号：{{ item.order_sn }}
                        p.examine(
                            :class="item.order_status == 0 ? 'red' : 'green'"
                        ) {{ item.status_text }}
                    .order-i
                        .imgbox(
                            :style="{ backgroundImage: 'url(' + item.thum_image_url + ')' }"
                        )
                        .order-ibox 
                            p.ibox-p {{ item.title }}
                            p.ibox-s
                                strong.num {{ item.guide_price }}
                                //span.wan 万
                    .order-delete(@click="del(item.id)")
                        p 删除订单
                    //- .order-delete(v-if="item.order_status == 0",@click="del(item.id)")
                    //-     p 取消订单
    .order-box(v-if="active == 0") 
        van-list.xin-box(
            v-model="loading",
            :finished="finished",
            @load="onLoad"
        )
            template(#default)
                .order1-box(v-for="item in list1")
                    .order-t
                        p.num 订单号：{{ item.order_sn }}
                        p.examine.red {{ item.status_text }}
                    .order-i
                        .imgbox(
                            :style="{ backgroundImage: 'url(' + item.thum_image_url + ')' }"
                        )
                        .order-ibox 
                            p.ibox-p {{ item.title }}
                            p.ibox-s
                                strong.num {{ item.guide_price }}
                                //span.wan 万
                    .order-delete(@click="del(item.id)") 
                        p 删除订单
                    //- .order-delete(v-if="item.order_status == 0",@click="del(item.id)")
                    //-     p 取消订单
    .order-box(v-if="active == 1") 
        van-list.xin-box(
            v-model="loading",
            :finished="finished",
            @load="onLoad"
        )
            template(#default)
                .order1-box(v-for="item in list2")
                    .order-t
                        p.num 订单号：{{ item.order_sn }}
                        p.examine.green {{ item.status_text }}
                    .order-i
                        .imgbox(
                            :style="{ backgroundImage: 'url(' + item.thum_image_url + ')' }"
                        )
                        .order-ibox 
                            p.ibox-p {{ item.title }}
                            p.ibox-s
                                strong.num {{ item.guide_price }}
                                //span.wan 万
                    .order-delete(@click="del(item.id)")
                        p 删除订单
        van-dialog(v-model="delshow", showCancelButton)
</template>
<style src="./index.styl" lang="stylus"></style>
<style src="../../stylus/reset.styl" lang="stylus"></style>

<script>
import { getOrderList, delOrder } from "../../config/request";
import { Dialog } from "vant";
import ShareImpl from '@/weixinApi.js';

export default {
    name:"order",
    props: {},
    data() {
        return {
            active: -1,
            list: [],
            list1: [],
            list2: [],
            loading: false,
            finished: false,
            delshow: false,
            page: 0,
        };
    },
    components: {
        // Dialog,
    },

    async created() {
        this.getData();
    },

    // watch: {
    //     active(n, o) {
    //         this.page = 0;
    //         this.getData();
    //     },
    // },

    methods: {
        onLoad() {
            this.page++;
            // 异步更新数据
            this.getData();
        },

        chooseTab(val) {
            this.active = val;
            this.page = 1;
            this.getData();
        },

        //获取数据
        getData() {
            getOrderList({
                active: this.active,
                page: this.page,
            }).then((res) => {
                if (res.code === 20000) {
                    if (this.active == -1) {
                        let list = res.data;
                        this.fun.setMoreData(this, list, "list");
                    } else if (this.active == 0) {
                        let list1 = res.data;
                        this.fun.setMoreData(this, list1, "list1");
                    } else {
                        let list2 = res.data;
                        this.fun.setMoreData(this, list2, "list2");
                    }
                    let shareUrl = window.location.href;
                    // if(shareUrl.indexOf('from') != -1){
                    //   shareUrl = window.location.href.split("?")[0];
                    // }
                    // window.console.log(url + 'favicon.png');
                    console.log(res,11111);
                    let img = window.location.href.split("#")[0] + 'd.jpg';
                    let shareTitle = '米链谷川';
                    let shareDesc = res.data.share ? res.data.share.share_title : '我的订单';
                    console.log(shareDesc,'分享title')
                    let shareImg =  img;
                    // let shareImg = img;
                    console.log(shareImg,'图片111')
                    console.log(img,'本地111')
                    ShareImpl({
                      shareTitle,
                      shareDesc,
                      shareUrl: location.origin + location.pathname + "?share=true/" + location.hash,
                      shareImg
                    });
                }
            });
        },

        del(val) {
            this.delshow = true;
            Dialog.confirm({
                title: "删除",
                message: "是否确定删除该订单?",
            })
                .then(() => {
                    delOrder({ order_id: val }).then((res) => {
                        if (res.code === 20000) {
                            this.toast({ message: res.data });

                            //移除数组

                            this.list.forEach((item, index, arr) => {
                                if (item.id == val) {
                                    this.list.splice(index, 1);
                                }
                            });

                            this.list1.forEach((item, index, arr) => {
                                if (item.id == val) {
                                    this.list1.splice(index, 1);
                                }
                            });

                            this.list2.forEach((item, index, arr) => {
                                if (item.id == val) {
                                    this.list2.splice(index, 1);
                                }
                            });
                        }
                    });
                })
                .catch(() => {
                    // on cancel
                });
        },
    },
};
</script>
<style lang="stylus" scoped></style>